module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Assistika Outsourced technical writing","short_name":"Assistika","start_url":"/","background_color":"white","theme_color":"white","display":"Assistika","icon":"src/assets/img/siteIcon.png"},
    }]
